<template>
  <div class="user">
    <div class="study_header">
      <div class="study_header_name">{{userData.realname}}</div>
      <div class="study_header_day">这是你加入学习的第 {{day}} 天</div>
    </div>
    <div class="container margin">
      <side-nav>
        <div class="user__table">
          <div class="flex flex__align-center">
            <div class="user__table-text text__center border__right border__bottom">学习时长</div>
            <div
              class="user__table-data border__bottom"
            >{{userData.studyDurationText ? userData.studyDurationText : '00'}}</div>
          </div>
          <div class="flex flex__align-center">
            <div class="user__table-text text__center border__right border__bottom">获得学分</div>
            <div class="user__table-data border__bottom">{{userData.score}}</div>
          </div> 
          <div class="flex flex__align-center">
            <div class="user__table-text text__center border__right border__bottom">学习任务</div>
            <div class="user__table-data border__bottom">{{taskName?taskName:'暂无学习任务'}}</div>
          </div>
          <div class="flex flex__align-center">
            <div class="user__table-text text__center border__right border__bottom">所属部门</div>
            <div class="user__table-data border__bottom">{{depart}}</div>
          </div>
          <div class="flex flex__align-center">
            <div class="user__table-text text__center border__right border__bottom">所属岗位</div>
            <div class="user__table-data border__bottom">{{position ? position : '暂无'}}</div>
          </div>
          <div class="flex flex__align-center">
            <div class="user__table-text text__center border__right">手机号码</div>
            <div class="user__table-data">{{userData.phone}}</div>
          </div>
        </div>
      </side-nav>
    </div>
  </div>
</template>

<script>
import sideNav from "@/components/side-nav";
import { getUserData } from "@/api/user";
// import {  USER_NAME, POINT, SCORE, PHONE, STUDY_TIME, DEPARTMENT, POSITION } from '@/store/mutation-types.js'
import Vue from "vue";
export default {
  components: { sideNav },
  data() {
    return {
      userData: {},
      depart: "",
      position: "",
      taskName: Vue.ls.get("TASK_NAME"),
      studyDurationText: null,
      day: 0
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getUserData().then(res => {
        console.log(res);
        this.userData = res.result;
        this.setDate(this.userData.createTime)
        console.log(res.result.studyDurationText, "学习时长");
        if (res.result.studyDurationText === "00分") {
          this.userData.studyDurationText = "0分";
        }
        this.depart = this.userData.departList.join();
        if (this.userData.positionList !== null) {
          this.position = this.userData.positionList.join();
        }
      });
    },
    // 处理时间
    setDate(date) {
      var dateArr = date.split(' ')[0]
      var time = new Date(dateArr).getTime() / 1000 // 创建时间的时间戳
      var today = new Date().getTime() / 1000 // 当前时间的时间戳
      var daySeconds = 3600 * 24 // 一天的秒数
      this.day = parseInt((today - time) / daySeconds)
      // console.log(this.day)
    }
  }
};
</script>

<style lang = "scss" scoped>
.user {
  .study_header{
    width: 1200px;
    height: 100px;
    background: #5db4ff;
    box-shadow: 0px 5px 10px 0px #a7d6ff;
    border-radius: 15px;
    margin: 48px auto 24px;
    overflow: hidden;
    .study_header_name{
      font-size: 30px;
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 30px;
      margin-left: 48px;
      margin-top: 20px;
    }
    .study_header_day{
      font-size: 18px;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
      line-height: 18px;
      margin-left: 48px;
      margin-top: 16px;
    }
  }
  &__table {
    border: 1px solid #E4E4E4;
    flex: 1;
    &-text {
      width: 252px;
      line-height: 62px;
      font-size: 18px;
      font-family: MicrosoftYaHei;
      color: #989898;
    }
    &-data {
      width: 632px;
      line-height: 62px;
      font-size: 18px;
      font-family: MicrosoftYaHei;
      color: #989898;
      padding-left: 48px;
    }
  }
}
</style>
